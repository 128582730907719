import './App.css';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import {Map, Source, Layer, MapProvider, Marker, FullscreenControl} from 'react-map-gl';

import booleanPointOnLine from "@turf/boolean-point-on-line";
import nearestPointOnLine from "@turf/nearest-point-on-line";
import { nearestPoint } from "@turf/nearest-point";

import * as turf from '@turf/turf'
import { featureCollection, point } from "@turf/helpers";
import { mask } from "@turf/mask";

import BOUNDED_ROADS from './boundedroads.geojson';
import VERTICES from './vertices.geojson';
import BEDSTUY_EXTENT from './bedstuyextent.geojson';

const fetchJSON = async (url) => {
  const response = await fetch(url);
  return await response.json();
}

function App() {

  const [routeData, setRouteData] = useState({});
  const [algorithmOption, setAlgorithmOption] = useState('dijkstra')
  const [displayRoute, setDisplayRoute] = useState(false);
  const [showSettings, setShowSettings] = useState(true);
  const [maxLength, setMaxLength] = useState(0);
  const [routeScore, setRouteScore] = useState({
    'heatUnits': 0,
    'meanLst': 0
  });

  const [mask, setMask] = useState()
  const [feather, setFeather] = useState()

  useEffect(() => {
    const bedStuyMask = async () => {
      const extents = await fetchJSON(BEDSTUY_EXTENT)
      let extentsPolygon = turf.polygon(extents.geometry.coordinates)

      let buffered = turf.buffer(extentsPolygon, 0.015, { units: 'kilometers' });
      let featherRing = turf.difference(turf.featureCollection([buffered, extentsPolygon]));

      setFeather(featherRing)
      setMask(turf.mask(extentsPolygon))
    }

    bedStuyMask()

  }, [])

  useEffect(() => {
    console.log("new route data ", routeData)
  }, [routeData])

  const changeAlgorithm = (e) => {
    setAlgorithmOption(e.target.value)
  }

  const roundNum = (num) => Math.round((num + Number.EPSILON) * 100) / 100

  const routeFunction = async () => {

    const url = new URL("http://137.184.157.88/api/send_path");
    url.searchParams.set('beginLat', lngLatStart.lat);
    url.searchParams.set('beginLong', lngLatStart.lng);
    url.searchParams.set('endLong', lngLatEnd.lng);
    url.searchParams.set('endLat', lngLatEnd.lat);
  
    url.searchParams.set('beginId', lngLatStart.id);
    url.searchParams.set('endId', lngLatEnd.id);
    url.searchParams.set('algorithm', algorithmOption);

    await fetch(url.href, { method: 'GET' }).then(response => response.json())
      .then(data => { 
        setRouteData(JSON.parse(data.geojson))
        let meanScore = roundNum(data.mean)
        let heatUnits = roundNum(data.heatUnits)
        setRouteScore({'meanLst': meanScore, 'heatUnits': heatUnits})
        setDisplayRoute(true)
      })
    
  }

  const layerStyle = {
    id: 'routeData',
    type: 'line',
    paint: {
      'line-color': 'black',
      'line-opacity': 1,
      'line-width': 3
    }
  };
 
  const maskStyle = {
    id: 'mask',
    type: 'fill',
    paint: {
      'fill-color': 'black',
      'fill-opacity': 0.1,
    }
  };

  const startEnd = {
    start: "start",
    end: "end"
  }

  const [lngLatStart, setLngLatStart] = useState({
    lng: -73.9317,
    lat: 40.6905,
    id: 0
  });

  const [lngLatEnd, setLngLatEnd] = useState({
    lng: -73.92,
    lat: 40.6905,
    id: 0
  });

  const setLatLong = (lat, lng, id, type) => {
    if (type === startEnd.start) {
      setLngLatStart({lng, lat, id});
    } else if (type === startEnd.end) {
      setLngLatEnd({lng, lat, id});
    }
  }

  const onEndMarkerDrag = ((event, type) => {

    const markerPos = [event.lngLat.lng, event.lngLat.lat];

    // Change to BOUNDED_ROADS if using pixels
    fetchJSON(VERTICES).then((data) => {

      // Points instead
      const points = featureCollection(data.features.map((point) => turf.point(point.geometry.coordinates, {'id': point.properties.osmid})));
      console.log(points)

      const closestPoint = nearestPoint(markerPos, points);

      console.log(closestPoint.geometry.coordinates)
      console.log(closestPoint.properties.id)

      setLatLong(closestPoint.geometry.coordinates[1], closestPoint.geometry.coordinates[0], closestPoint.properties.id, type)

      // const coords = data.features.map((line) => line.geometry.coordinates);
      // const roadLines = turf.multiLineString(coords);
  
      // if (!booleanPointOnLine(markerPos, roadLines)) {

      //   const closestPoint = nearestPointOnLine(roadLines, markerPos);
  
      //   console.log(closestPoint.geometry.coordinates)

      //   setLatLong(closestPoint.geometry.coordinates[1], closestPoint.geometry.coordinates[0], type);
  
      // } else {
  
      //   setLatLong(event.lngLat.lat, event.lngLat.lng, type);
  
      // }

    });

    console.log(lngLatStart)
  });

  return (
    <Stack alignItems='center' sx={{height: 'auto'}}>
      <Stack className="header" direction="row" alignItems='center' justifyContent='center' spacing="20vw" sx={{position: 'fixed', backgroundColor: 'white', zIndex: '5', width: '100vw', padding: '20px'}}>
        <Stack direction="row" alignItems='center' justifyContent='left' spacing="25px" sx={{width: '25vw'}}>
          <img alt="Cool Corridor Routing" src="coco-logo.png" width="100px"></img>
          {/* <p>Team</p>
          <p>Story</p> */}
        </Stack>
        <Stack direction="row" alignItems='center' justifyContent='right' spacing="25px" sx={{width: '25vw'}} >
          <img alt="Columbia" src="https://www.earth.columbia.edu/img/logos/logo-ei-blue-400.png" width="200px"></img>
          {/* <img alt="NASA Meatball" src="https://gpm.nasa.gov/sites/default/files/NASA-Logo-Large.jpg" width="50px"></img> */}
          <img alt="City Tech" src="https://historyprogram.commons.gc.cuny.edu/files/2015/08/CityTech.jpeg" width="40px"></img>
        </Stack>
      </Stack>

      <Stack alignItems='center' justifyContent='center' sx={{height: 'auto', marginTop: '80px'}}>
        <Box sx={{width: '70vw', margin: '30px 0px 20px 0px'}}>
          {/* <h1>Cool Corridor Routing</h1> */}
          {/* <img alt="Cool Corridor Routing" src="coco-black.png" width="300px"></img> */}
          <Box sx={{textAlign: 'left'}}>
            <h1>Helping you find the coolest, most comfortable route</h1>
          </Box>
          <p>Select a start and end point by dragging the markers designated 'S' and 'E,' respectively. The coolest path will be generated. An LST score for this path will appear in the top left corner under 'LST Score.'</p>
        </Box>
        <Stack alignItems='center'>
          <Box sx={{width: '80vw', height: '80vh'}}>
              <MapProvider>
                <Map
                  id="coolMap"
                  mapboxAccessToken="pk.eyJ1Ijoic2tydW1iIiwiYSI6ImNsemdia3hyZTFodmgyaXEyaTJ5eHA3NnUifQ.wawm29g1W7vDJLo5Ktmx_A"
                  initialViewState={{
                    longitude: -73.9317,
                    latitude: 40.6905,
                    zoom: 13,
                    pitch: 15

                  }}
                  mapStyle="mapbox://styles/mapbox/light-v11"
                  className="mapContainer"
                  // terrain={{"source": "mapbox-raster-dem", "exaggeration": 2}}
                >
                  <FullscreenControl position='top-left'/> 

                {/*<div className="sidebar">
                  <div>
                    <h2>Path Metrics</h2>
                  </div>

                  <Box>
                    <hr/>
                    <p>Mean exposure: {routeScore.meanLst} °C</p>
                    <p>Cumulative heat exposure: {routeScore.heatUnits} degree / meter</p>

                  </Box>

                </div>*/}
                    {/*<hr/>
                    <p>Heat exposure per minute: </p>
                    <p>Estimated travel time: </p>*/}

                <Stack direction="column" spacing="3" className="sendDiv">
                  <Button className="sendButton" variant="contained" onClick={async () => {
                    await routeFunction()
                  }}> Find Path </Button>

                  <Stack direction="column" spacing="10px" className="innerSendDiv">
                    {/* Edit to be controlled input */}
                      <TextField onChange={(e) => {}} id="outlined-basic" label={'Start'} value={`${lngLatStart.lat.toFixed(5)}, ${lngLatStart.lng.toFixed(5)}`} variant="outlined" />
                      <TextField onChange={(e) => {}} id="outlined-basic" label={'Destination'} value={`${lngLatEnd.lat.toFixed(5)}, ${lngLatEnd.lng.toFixed(5)}`} variant="outlined" />
                  </Stack>

                  <Stack direction="column" spacing="20px" className="innerSendDiv">
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel>Algorithm</InputLabel>
                      <Select
                        value={algorithmOption}
                        label="Algorithm"
                        onChange={changeAlgorithm}
                      >
                        <MenuItem value={'dijkstra'}>Dijkstra</MenuItem>
                        <MenuItem value={'normalized'}>Normalized Dijkstra</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  </Stack>
                  <Stack direction="column" spacing="20px" className="innerSendDiv">

                  <Box>
                    <h2>Path Exposure Metrics</h2>
                    <hr/>
                    {/* <p>Mean exposure: {routeScore.meanLst} °C</p> */}
                    <p>Cumulative heat exposure: {routeScore.heatUnits} degree * meter</p>
                    {/*<hr/>
                    <p>Heat exposure per minute: </p>
                    <p>Estimated travel time: </p>*/}

                  </Box>
                  </Stack>

                  {/* <Stack direction="column" spacing="20px" className="innerSendDiv">
                    <h2>Ok with a longer, cooler route?</h2>
                    {showSettings && <>
                      <p>How many more meters are you willing to walk? Specify a maximum path length:</p>
                      <TextField onChange={(e) => {setMaxLength(e.target.value)}} id="outlined-basic" label={`${maxLength} Meters`} variant="outlined" />
                    </>}
                    
                    {
                      showSettings &&
                      <Button variant="outlined" onClick={() => {setShowSettings(prevState => !prevState)}}>Close</Button>
                    }

                    {
                      !showSettings &&
                      <Button variant="outlined" onClick={() => {setShowSettings(prevState => !prevState)}}>See Options</Button>
                    }

                  </Stack> */}
                </Stack>

                <Source id="boundary" type="geojson" data={mask}>
                  <Layer {...maskStyle} />
                </Source>

                {/* <Source id="feather" type="geojson" data={feather}>
                  <Layer {...featherStyle} />
                </Source> */}

                {displayRoute && <Source id="routeData" type="geojson" data={routeData}>
                  <Layer {...layerStyle} />
                </Source>}

                <Marker 
                  draggable
                  longitude={lngLatEnd.lng} 
                  latitude={lngLatEnd.lat} 
                  onDragStart={(e) => {
                    setDisplayRoute(false)
                    setRouteScore({'meanLst': 0, 'heatUnits': 0})              
                  }}
                  onDragEnd={(e) => onEndMarkerDrag(e, startEnd.end)} 

                  anchor="bottom" >
                  <img src="end.png" width="30px" height="auto" alt="yo"/>
                </Marker>

                <Marker 
                  draggable
                  longitude={lngLatStart.lng} 
                  latitude={lngLatStart.lat}
                  onDragStart={(e) => {
                    setDisplayRoute(false)
                    setRouteScore({'meanLst': 0, 'heatUnits': 0})              
                  }}
                  onDragEnd={(e) => onEndMarkerDrag(e, startEnd.start)} 
                  anchor="bottom" >
                  <img src="start.png" width="30px" height="auto" alt="yo"/>
                </Marker>

              </Map>
            </MapProvider>  
          </Box>
        </Stack>
      </Stack>
      <Stack sx={{width: '70vw', margin: '30px'}}>
        <Box sx={{textAlign: 'justify'}}>
          <h1>What does this tool do?</h1>
          <p> Cool corridor routing software aims to help pedestrians find the coolest way to a cooling zone or to their home so that they can avoid heat related discomfort. Officials may also  generate heat maps using tools like this to identify streets that become dangerously hot during extreme heat events.</p>
        </Box>


        <Box sx={{textAlign: 'justify'}}>
          <h1>How are cool paths calculated?</h1>
          <p>Cool paths are calculated using a modified version of Dijkstra's algorithm. You can read more about Dijkstra's algorithm <a href="https://en.wikipedia.org/wiki/Dijkstra%27s_algorithm">here</a>. An LST image is projected over a road network and each LST pixel intersecting each road in this road network is added to a mean LST for each road. Once the graph has been generated using the intersecting LST pixels, Dijkstra's algorithm is run over the edges in the graph.</p>
        </Box>

        <Box sx={{textAlign: 'justify'}}>
          <h1>Team and Affiliations</h1>
          <p>Matthew Bilik (University of Michigan), Jack Lefkowitz (Cornell University), Hamidreza Norouzi (New York City College of Technology), Reginald Blake (New York City College of Technology)</p>
          {/* <p>NASA GISS Climate Impacts Group (CIG) with support from the NASA Advanced Information Systems Technology program</p> */}
        </Box>


        {/* <Box sx={{textAlign: 'justify'}}>
          <h1>Why Bed-Stuy?</h1>
          <p>Bed-Stuy is.</p>
        </Box>
 */}
      </Stack>
      <footer className='footer'>
        <div>
          {/* <p>NASA Goddard Institute for Space Studies</p>
          <p>NASA Advanced Information Systems Technology</p> */}
          <p>New York City College of Technology</p>
          <p>Columbia University</p>
        </div>
      </footer>
    </Stack>
  );
}

export default App;
